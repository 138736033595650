$black: #000;
$light-black: #212529;
$white: #fff;
$grey: #9e9e9e;
$light-grey: #efefef;
$snow-white: #f9f9f9;
$primary: #ffa726;
$dark-orange: #c77800;
$orange: #ffa726;
$light-orange: #ffcc80;
$yellow: #ffd95b;
$light-yellow: #ffffb0;
$border-color: #e5dfc7;
$dark-red: #b00020;
$yellow-linear-gradient-to-bottom: linear-gradient(190deg, #ffbc59 -5.61%, #ff9800 105%);
$blue-linear-gradient-to-right: linear-gradient(270deg, #55A9BB 32.86%, #2D4F5D 106.72%);
$blue-linear-gradient-to-left: linear-gradient(90deg, #55A9BB 32.86%, #2D4F5D 106.72%);
$link-color: #006eec;
$danger: #e10f0f;


// redesign

$aot-black: #121212;
$aot-grey: #12121214;
$aot-light-black: #1212120F;
$aot-dark-black: #00000014;
$aot-lemon-chiffon: #FFEDC6;
$aot-forest-green: #0d392e;
$green: #00C48C;
$red: #FF647C;
$blue: #0084F4;
$astral: #388A9C;

$orange-50: #FFF8EB;
$orange-200: #FFD888;
$orange-300: #FFBE4A;
$orange-400: #FFA726;
$orange-500: #F98007;
$orange-600: #DD5B02;
$orange-700: #B73D06;
$orange-800: #942E0C;
$orange-900: #7A260D;

$aot-black-50: #F5F5F5;
$aot-black-100: #E6E6E6;
$aot-black-200: #CCCCCC;
$aot-black-300: #B3B3B3;
$aot-black-400: #999999;
$aot-black-500: #888888;
$aot-black-600: #666666;
$aot-black-700: #4D4D4D;
$aot-black-800: #333333;
$aot-black-900: #1A1A1A;

$aot-gray-700: #4C5761;

$red-100: #FFCCD4;
$red-200: #FF99A8;
$red-300: #FF667D;
$red-400: #FF405C;
$red-500: #FF3352;
$red-600: #CC001F;
$red-700: #990017;
$red-800: #66000F;
$red-900: #330008;

$green-100: #CCFFF1;
$green-200: #99FFE2;
$green-300: #66FFD4;
$green-400: #33FFC5;
$green-500: #00CC92;
$green-600: #00C48C;
$green-700: #00996E;
$green-800: #006649;
$green-900: #003325;

$astral-50: #F1FAFA;
$astral-100: #DBEFF2;
$astral-200: #BBE1E6;
$astral-300: #8CCAD4;
$astral-400: #55A9BB;
$astral-500: #388A9C;
$astral-600: #337487;
$astral-700: #2F5F6F;
$astral-800: #2D4F5D;
$astral-900: #294450;