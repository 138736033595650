@font-face {
    font-family: "aot-icomoon";
    src: url("../fonts/icon-font/custom/icomoon.eot?if7j2c");
    src: url("../fonts/icon-font/custom/icomoon.eot?if7j2c#iefix") format("embedded-opentype"),
        url("../fonts/icon-font/custom/icomoon.ttf?if7j2c") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-aot"],
[class*=" icon-aot"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "aot-icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-aot-dashboard:before {
    content: "\e900";
}
.icon-aot-mali-servis:before {
    content: "\e901";
}
.icon-aot-troskovi:before {
    content: "\e902";
}
.icon-aot-vozila:before {
    content: "\e903";
}
.icon-aot-add:before {
    content: "\e904";
}
.icon-aot-alert:before {
    content: "\e905";
}
.icon-aot-arrow-down:before {
    content: "\e906";
}
.icon-aot-calendar:before {
    content: "\e907";
}
.icon-aot-close:before {
    content: "\e908";
}
.icon-aot-delete:before {
    content: "\e909";
}
.icon-aot-photo:before {
    content: "\e90a";
}
.icon-aot-edit:before {
    content: "\e90b";
}
.icon-aot-engine:before {
    content: "\e90c";
}
.icon-aot-filter:before {
    content: "\e90d";
}
.icon-aot-fuel:before {
    content: "\e90e";
}
.icon-aot-insurance:before {
    content: "\e90f";
}
.icon-aot-menu:before {
    content: "\e910";
}
.icon-aot-notification:before {
    content: "\e911";
}
.icon-aot-other:before {
    content: "\e912";
}
.icon-aot-registration:before {
    content: "\e913";
}
.icon-aot-search:before {
    content: "\e914";
}
.icon-aot-sort:before {
    content: "\e915";
}
.icon-aot-tire:before {
    content: "\e916";
}
.icon-aot-wash:before {
    content: "\e917";
}
