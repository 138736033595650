h1 {
    font-size: 48px;
    line-height: 56px;

    &.bold-1 {
        font-weight: 800;
    }
}

h2 {
    font-size: 40px;
    line-height: 48px;

    &.bold-1 {
        font-weight: 800;
    }

    &.bold-2 {
        font-weight: 700;
    }
}

h3 {
    font-size: 32px;
    line-height: 40px;

    &.bold-1 {
        font-weight: 800;
        color: $aot-black-800;
    }

    &.regular-color {
        color: $aot-black-700;
    }

    &.bold-2 {
        font-weight: 600;
    }
}


h4 {
    font-size: 24px;
    line-height: 32px;

    &.bold-1 {
        font-weight: 700;
        color: $aot-black-800;
    }

    &.bold-2 {
        font-weight: 600;
    }
}

h5 {
    font-size: 16px;
    line-height: 24px;

    &.bold-1 {
        font-weight: 700;
    }

    &.bold-2 {
        font-weight: 400;
    }
}

h6 {
    font-size: 8px;

    &.bold-1 {
        font-weight: 700;
    }

    &.bold-2 {
        font-weight: 400;
    }
}

// font-size

.p {
    font-size: 8px;
}

.p1 {
    font-size: 12px;
}

.p2 {
    font-size: 14px;
}

.p3 {
    font-size: 16px;
}

.p4 {
    font-size: 18px;
}

.p5 {
    font-size: 20px;
}

.p6 {
    font-size: 32px;
}

// p-color

.p-color-0 {
    color: $aot-black-50;
}

.p-color-1 {
    color: $aot-black-100;
}

.p-color-2 {
    color: $aot-black-200;
}

.p-color-3 {
    color: $aot-black-300;
}

.p-color-4 {
    color: $aot-black-400;
}

.p-color-5 {
    color: $aot-black-500;
}

.p-color-6 {
    color: $aot-black-600;
}

.p-color-7 {
    color: $aot-black-700;
}

.p-color-8 {
    color: $aot-black-800;
}

.p-color-9 {
    color: $aot-black-900;
}

.p-color-10 {
    color: $aot-gray-700;
}

.p-color-11 {
    color: $astral-800;
}

// font-weight

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

// line-height

.line-height-16 {
    line-height: 16px;
}

.line-height-20 {
    line-height: 20px;
}

.line-height-24 {
    line-height: 24px;
}

.line-height-40 {
    line-height: 40px;
}

.line-height-56 {
    line-height: 56px;
}

// margin

.m-t-1 {
    margin-top: 8px;
}

.m-t-2 {
    margin-top: 16px;
}

.m-t-3 {
    margin-top: 24px;
}

.m-t-4 {
    margin-top: 32px;
}

.m-t-5 {
    margin-top: 40px;
}

.m-t-6 {
    margin-top: 48px;
}

.m-t-7 {
    margin-top: 56px;
}

.m-t-8 {
    margin-top: 64px;
}

.m-b-1 {
    margin-bottom: 8px;
}

.m-b-2 {
    margin-bottom: 16px;
}

.m-b-3 {
    margin-bottom: 24px;
}

.m-b-4 {
    margin-bottom: 32px;
}

.m-b-5 {
    margin-bottom: 40px;
}

.m-b-6 {
    margin-bottom: 48px;
}

.m-b-7 {
    margin-bottom: 56px;
}

.m-b-8 {
    margin-bottom: 64px;
}


.m-l-1 {
    margin-left: 8px;
}

.m-l-2 {
    margin-left: 16px;
}

.m-l-3 {
    margin-left: 24px;
}

.m-l-4 {
    margin-left: 32px;
}

.m-l-5 {
    margin-left: 40px;
}

.m-l-6 {
    margin-left: 48px;
}

.m-l-7 {
    margin-left: 56px;
}

.m-l-8 {
    margin-left: 64px;
}

.m-r-1 {
    margin-right: 8px;
}

.m-r-2 {
    margin-right: 16px;
}

.m-r-3 {
    margin-right: 24px;
}

.m-r-4 {
    margin-right: 32px;
}

.m-r-5 {
    margin-right: 40px;
}

.m-r-6 {
    margin-right: 48px;
}

.m-r-7 {
    margin-right: 56px;
}

.m-r-8 {
    margin-right: 64px;
}

// padding

.p-t-1 {
    padding-top: 8px;
}

.p-t-2 {
    padding-top: 16px;
}

.p-t-3 {
    padding-top: 24px;
}

.p-t-4 {
    padding-top: 32px;
}

.p-t-5 {
    padding-top: 40px;
}

.p-t-6 {
    padding-top: 48px;
}

.p-t-7 {
    padding-top: 56px;
}

.p-t-8 {
    padding-top: 64px;
}

.p-b-1 {
    padding-bottom: 8px;
}

.p-b-2 {
    padding-bottom: 16px;
}

.p-b-3 {
    padding-bottom: 24px;
}

.p-b-4 {
    padding-bottom: 32px;
}

.p-b-5 {
    padding-bottom: 40px;
}

.p-b-6 {
    padding-bottom: 48px;
}

.p-b-7 {
    padding-bottom: 56px;
}

.p-b-8 {
    padding-bottom: 64px;
}

.p-l-1 {
    padding-left: 8px;
}

.p-l-2 {
    padding-left: 16px;
}

.p-l-3 {
    padding-left: 24px;
}

.p-l-4 {
    padding-left: 32px;
}

.p-l-5 {
    padding-left: 40px;
}

.p-l-6 {
    padding-left: 48px;
}

.p-l-7 {
    padding-left: 56px;
}

.p-l-8 {
    padding-left: 64px;
}

.p-r-1 {
    padding-right: 8px;
}

.p-r-2 {
    padding-right: 16px;
}

.p-r-3 {
    padding-right: 24px;
}

.p-r-4 {
    padding-right: 32px;
}

.p-r-5 {
    padding-right: 40px;
}

.p-r-6 {
    padding-right: 48px;
}

.p-r-7 {
    padding-right: 56px;
}

.p-r-8 {
    padding-right: 64px;
}