@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "/src/assets/scss/colors.scss";
@import "/src/assets/scss/buttons.scss";
@import "/src/assets/scss/typography.scss";
@import "/src/assets/scss/forms.scss";
@import "/src/assets/scss/mixins.scss";

html,
body {
    font-family: "Manrope", sans-serif;
    background-color: $aot-black-50;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

body {
    min-height: 100vh;
    position: relative;
}

p {
    line-height: 1.75em;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 60px;
    /* Footer height */
    clear: both;
    background-color: $light-black;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
    }
}

.external-link::after {
    content: "↗";
    font-size: 0.75em;
    margin-left: 0.2em;
    vertical-align: middle;
}

.text-sm {
    font-size: 10px;
}

.fe-text-warning {
    color: $orange-500 !important
}

.disable-mouse-events {
    pointer-events: none !important;
}

.page-item.active .page-link {
    border-color: $primary;
    background-color: $primary;
}

.page-link,
.page-link:hover {
    color: $primary;
}

.pull-right {
    float: right;
}

.page-action {
    i.icon-plus {
        font-size: 12px;
        margin-right: 3px;
    }
}

.pull-left {
    float: left;
}

.wrapper {
    background-color: transparent;
    position: relative;
    padding: 0;
    margin: 1px auto;
    float: left;
    width: 100%;

    .error-message {
        font-size: 12px;
        float: left;
        text-align: left;
        width: auto;
        padding-left: 0;
        position: relative;
        margin: -5px auto 10px;
        color: $red-400 !important;
        padding-left: 20px;

        i {
            font-size: 15px;
            position: absolute;
            left: 0;
            top: 0;
            color: $red-500;
        }

        &.align-right {
            float: right;
        }
    }
}

// ng-select start
.form-group {
    .ng-select {
        margin: 8px auto 12px;

        .ng-select-container {
            cursor: pointer !important;
            border-radius: 10px;
            border-color: $aot-black-200;
            min-height: 48px;
            height: 48px;

            .ng-value-container .ng-input {
                height: 100%;
                top: 0;

                & > input {
                    cursor: pointer !important;
                    height: 100%;
                }
            }
        }

        .ng-arrow {
            display: none;
            border: none !important;
        }

        .ng-arrow-wrapper {
            background-image: url("/assets/img/icons/caret-down.svg");
            background-repeat: no-repeat;
            width: 21px;
            margin: 0px 20px;
            padding: 0px;
            opacity: 0.5;
        }

        .ng-option {
            position: relative;
            padding: 12px 16px 12px 16px;

            &.ng-option-marked {
                background-color: #cccccc !important;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(18, 18, 18, 0.0784313725);
                margin-left: 8px;
            }

            &:last-child::after {
                display: none;
            }

            /* checked */
            &.ng-option-selected {
                background-color: white !important;
                color: #1a1a1a !important;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    width: 19px;
                    height: 13px;
                    background-image: url("/assets/img/icons/check.svg");
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }

        .ng-dropdown-panel {
            // bottom
            &.ng-select-bottom {
                border-radius: 0px 0px 16px 16px;
                border: 1px solid #cccccc;
                box-shadow: 0px 4px 8px 0px #1212120f;
                box-shadow: 0px 4px 4px 0px #00000014;
                margin-top: 15px;
                overflow: hidden;

                & > .ng-option:first-child::after {
                    display: none;
                }
            }

            // top
            &.ng-select-top {
                border-radius: 16px 16px 0px 0px;
                box-shadow: 0px 4px 8px 0px #1212120f;
                box-shadow: 0px 4px 4px 0px #00000014;
                margin-bottom: 15px;
                overflow: hidden;

                & > .ng-select-container {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }

                & > .ng-dropdown-panel-items {
                    border-radius: 16px 16px 0px 0px;
                }
            }
        }
    }

    .ng-select-focused {
        .ng-select-container {
            border-color: $aot-black-200 !important;
            box-shadow: none !important;
        }
    }

    .ng-select-opened {
        .ng-arrow-wrapper {
            opacity: 1;
        }

        &.ng-select-bottom > .ng-select-container {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
}

// ng-select end

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-radius: 10px;
}

textarea.form-control {
    min-height: 100px;
    line-height: 24px;

    &.no-resize {
        resize: none !important;
    }
}

.form-group {
    [class^="icon-eye"] {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: $grey;
        padding: 10px 0 10px 10px;

        &:hover {
            color: $black;
        }
    }

    label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $aot-black-600;

        &.form-check-label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $aot-gray-700;
            width: calc(100% - 32px);
            padding-left: 16px;
        }

        &.required {
            position: relative;

            &:after {
                position: absolute;
                content: "*";
                right: -10px;
                top: 0;
                color: $red;
            }
        }
    }
}

.btn {
    border-radius: 8px;

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
        color: inherit !important;
    }

    &:hover {
        background-color: $orange-300;
        border-color: $orange-300;
    }

    &.btn-primary {
        background-color: $orange-500;
        border-color: $orange-500;
        justify-content: space-between;
        color: $white;

        &:focus,
        &:active,
        &.dropdown-toggle {
            background-color: $orange-400;
            border-color: $primary;
        }
    }

    &.btn-secondary {
        background-color: $white;
        border-color: $orange-500;
        color: $aot-black-900;

        &:hover {
            color: $aot-black-900;
        }
    }

    &.btn-small {
        padding: 8px 16px;
    }

    &:disabled {
        background-color: $aot-black-100;
        color: $black;
        border-color: $light-grey;
    }

    &.fe-btn {
        height: 56px;
        width: auto;
        font-weight: 500;
        padding: 16px;
        line-height: 24px;
        min-width: 250px;
        font-size: 16px;
        max-width: 100%;
    }

    &.btn-outline {
        box-shadow: none;
        background-color: transparent;
        background: none;
        border-color: $primary;

        &.btn-trans-border {
            border-color: transparent;
            color: $primary;
        }
    }

    &.btn-quick-action {
        border: none !important;
        color: $primary;
        line-height: 20px;
        font-weight: 800;

        i {
            font-size: 1.5rem;
            position: relative;
            top: 5px;
        }

        &:focus {
            color: $primary !important;
        }
    }

    &.btn-danger {
        color: #fff;
        justify-content: space-between;
        background-color: $red-500;
        border-color: $red-500;

        &:hover {
            background-color: $red-500;
            border-color: $red-500;
        }
    }

    &.btn-block {
        display: block;
        width: 100%;
    }

    &.min-width {
        min-width: 150px;
    }

    i.icon-arrow-thin-left {
        font-size: 12px;
        margin-right: 10px;
    }
}

.btn-disabled {
    opacity: 0.5;
}

.btn:focus,
.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    color: inherit;
}

.form-control input:focus {
    border-color: $aot-black-200;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.08),
        0px 4px 8px rgba(18, 18, 18, 0.06) !important;
    border-radius: 8px;
}

.form-control:hover {
    border-color: $aot-black-200;
    background: #ffffff;
    border: 1px solid $aot-black-200;
    box-shadow:
        0px 8px 8px rgba(0, 0, 0, 0.08),
        0px 8px 16px rgba(18, 18, 18, 0.06);
    border-radius: 8px;
}

.form-control {
    height: 48px;
    padding: 8px 16px;
    line-height: 40px;
    margin: 8px auto 16px;
    border-radius: 10px;
    border-color: $aot-black-200;

    &.single-line {
        border-top: none;
        border-left: none;
        border-right: none;
        margin-top: 5px;
        border-radius: 0;
        padding: 0;
    }
}

input.ng-invalid.ng-dirty,
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-touched {
    border-color: $red-500;
    background: #ffffff;
    border: 1px solid $red-500;
    box-shadow: 0px 4px 8px rgba(255, 51, 82, 0.08);
    border-radius: 8px;

    &.single-line {
        border-top: none;
        border-left: none;
        border-right: none;
    }
}

.base-form {
    max-width: 450px;
    margin: 0 auto;

    .inner {
        margin: 0 15px;
    }
}

p {
    font-size: 15px;
}

.fe-fs-small {
    font-size: 12px;
}

.fe-relative {
    position: relative;
}

.fe-absolute {
    position: absolute;
}

.fe-opacity-light {
    opacity: 0.75;
}

.fe-opcity-medium {
    opacity: 0.75;
}

.fe-opcity-hard {
    opacity: 0.75;
}

.fe-full-width {
    width: 100% !important;
}

.fe-margin-center {
    margin: 0 auto;
}

.fe-no-margin {
    margin: 0 !important;
}

.fe-m-t-small {
    margin-top: 10px;
}

.fe-m-t-medium {
    margin-top: 25px;
}

.fe-m-t-big {
    margin-top: 50px;
}

.fe-m-t-extra-big {
    margin-top: 100px;
}

.fe-m-b-small {
    margin-bottom: 10px;
}

.fe-m-b-medium {
    margin-bottom: 25px;
}

.fe-m-b-big {
    margin-bottom: 50px;
}

.fe-m-b-extra-big {
    margin-bottom: 100px;
}

.fe-m-l-small {
    margin-left: 10px;
}

.fe-m-l-medium {
    margin-left: 25px;
}

.fe-m-l-big {
    margin-left: 50px;
}

.fe-m-l-extra-big {
    margin-left: 100px;
}

.fe-m-r-small {
    margin-right: 10px;
}

.fe-m-r-medium {
    margin-right: 25px;
}

.fe-m-r-big {
    margin-right: 50px;
}

.fe-m-r-extra-big {
    margin-right: 100px;
}

.fe-p-t-small {
    padding-top: 10px;
}

.fe-p-t-medium {
    padding-top: 25px;
}

.fe-p-t-big {
    padding-top: 50px;
}

.fe-p-t-extra-big {
    padding-top: 100px;
}

.fe-p-b-small {
    padding-bottom: 10px;
}

.fe-p-b-medium {
    padding-bottom: 25px;
}

.fe-p-b-big {
    padding-bottom: 50px;
}

.fe-p-b-extra-big {
    padding-bottom: 100px;
}

.fe-p-l-small {
    padding-left: 10px;
}

.fe-p-l-medium {
    padding-left: 25px;
}

.fe-p-l-big {
    padding-left: 50px;
}

.fe-p-l-extra-big {
    padding-left: 100px;
}

.fe-p-r-small {
    padding-right: 10px;
}

.fe-p-r-medium {
    padding-right: 25px;
}

.fe-p-r-big {
    padding-right: 50px;
}

.fe-p-r-extra-big {
    padding-right: 100px;
}

.fe-bold {
    font-weight: 500;
}

.fe-extra-bold {
    font-weight: 600;
}

.fe-cursor-pointer {
    cursor: pointer;
}

.fe-cursor-default {
    cursor: default;
}

.fe-no-padding {
    padding: 0 !important;
}

.fe-pl-0 {
    padding-left: 0;
}

.fe-pr-0 {
    padding-right: 0;
}

.fe-transform-xy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.fe-transform-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.fe-transform-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.fe-muted {
    color: $grey;
}

.fe-table-holder {
    position: relative;
    overflow-x: auto;

    .table > :not(caption) > * > * {
        padding: 1rem 1rem;
    }

    .table > :not(:first-child) {
        border-top: none;
    }

    thead tr th {
        background-color: #e5dfc7 !important;
    }
}

.fe-block-container {
    text-align: left;

    .block-content,
    .block-title {
        display: block;
        font-size: 16px;
        padding-bottom: 20px;
    }

    .block-title {
        color: $black;
        font-weight: 500;
    }

    .block-content {
        color: $grey;
    }
}

.or-separator {
    text-align: center;
    color: #797979;
    margin: 15px auto 30px;
    position: relative;
    height: 20px;
    float: left;
    width: 100%;

    span {
        width: auto;
        display: inline-block;
        overflow: hidden;
        line-height: 20px;
        position: relative;
        background-color: #fff;
        padding: 0 10px;
    }

    &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $border-color;
        position: absolute;
        left: 0;
        top: 10px;
    }
}

.social-btn {
    border: none;
    background-color: transparent;
    box-shadow: none;
    border-radius: 50%;

    &.facebook {
        margin-right: 10px;

        img {
            width: 35px;
        }
    }

    &.google {
        margin-left: 10px;
    }

    img {
        border-radius: 50%;
        width: 30px;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $primary;
}

.dropdown-menu {
    padding: 0;
    min-width: 162px;
    box-shadow: 0px 4px 8px 0px $aot-light-black;
    box-shadow: 0px 4px 4px 0px $aot-dark-black;
    border-radius: 8px;
    border: 1px solid $aot-grey;

    .dropdown-item {
        i {
            font-size: 18px;
            vertical-align: middle;
            margin-right: 5px;
        }

        &.divider {
            background-color: $aot-grey;
            height: 1px;
            padding: 0 !important;
            width: calc(100% - 18px);
            float: right;
        }
    }
}

.page-heading {
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
}

.fe-context-action {
    position: absolute;
    top: 10px;
    right: 10px;

    .btn-context-menu {
        &::after {
            display: none;
        }

        padding: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;

        i {
            color: $primary;
            font-size: 23px;
            position: relative;
            top: 3px;
        }

        &.horizontal {
            transform: rotate(90deg);
        }
    }
}

.dropdown-menu {
    &.show {
        background-color: $snow-white;

        .dropdown-item {
            padding: 8px 16px 8px 16px;
        }

        .dropdown-item:hover,
        .dropdown-item:focus {
            color: $white;
            background-color: $aot-black-500;
        }

        .dropdown-item:hover:first-child,
        .dropdown-item:focus:first-child {
            border-radius: 8px 8px 0px 0px;
        }

        .dropdown-item:hover:last-child,
        .dropdown-item:focus:last-child {
            border-radius: 0px 0px 8px 8px;
        }
    }

    .dropdown-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1a1a1a;

        i {
            font-size: 17px;
        }
    }
}

agm-map {
    height: 300px;
}

.btn-holder {
    align-items: center;
    padding: 4px;
    gap: 8px;
    height: 48px;
    background: $white;
    border: 1px solid $aot-black-200;
    border-radius: 8px;
}

.fe-no-border {
    border: none !important;
}

.btn-swich-holder {
    width: 100%;

    .btn {
        width: auto;
        min-width: 130px;
        font-size: 14px;
        border-color: $border-color;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $aot-black-400;

        &.active {
            background-color: $orange-300;
            border: 1px solid $orange-500;
            color: $black;
            height: 40px;
            padding: 2px 0px;
            border-radius: 8px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $orange-800;

            i {
                color: $dark-orange;
            }
        }
    }
}

.aot-box {
    border-radius: 10px;
    background-color: $snow-white;
    border: 1px solid $border-color;
    padding: 20px 25px;
    margin-bottom: 20px;
    position: relative;
    float: left;
    width: 100%;

    h5 {
        font-size: 18px;
        font-weight: 500;
        max-width: 95%;

        .subtitle {
            display: block;
            margin-top: 10px;
            font-weight: normal;
        }

        a {
            color: $light-black;
        }
    }

    .action-btn-top-right {
        position: absolute;
        right: 25px;
        top: 20px;
    }

    &.vehicle-holder {
        padding: 16px;
        border: none;
        background-color: #fff;

        .fav-icon-holder {
            position: absolute;
            right: 16px;
            top: 16px;

            i {
                font-size: 24px;
                cursor: pointer;

                &.icon-star-full {
                    color: $red-300;
                }
            }
        }

        .vehicle-logo-holder {
            padding: 0 16px;
            width: 104px;

            .vehicle-logo {
                width: 100%;
            }
        }

        .content-holder {
            width: calc(100% - 104px);
            float: left;
            padding-right: 0;
            margin: 0;

            .vehicele-specs {
                margin-top: 24px;
                position: relative;

                .label-box {
                    margin: 0 8px 0 0;
                    padding: 0;
                    text-align: left;

                    img {
                        float: left;
                        margin-right: 8px;
                        margin-top: 1px;
                    }
                }
            }
        }

        h5 {
            max-width: 90%;

            a {
                font-size: 24px;
                text-decoration: none;
                font-weight: 700;
            }

            .subtitle {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.fe-box-shadow {
    box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08);
    border: none;
}

form label {
    font-size: 14px;
    opacity: 0.75;
}

.page-step-back {
    color: $link-color;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
}

.label-box-wrapper {
    width: 100px;
    border: 1px solid #efefef;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    height: 100px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    margin: 0 auto;
    background-color: #fff;

    .expense-box {
        margin: 0 auto;
        width: 100%;

        span {
            opacity: 0.8;
            top: 0;
            display: inline-block;
            margin-top: 5px;
        }
    }

    &.box-link {
        width: 100%;
        margin: 0 auto 20px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);

        &:hover {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        }

        .expense-box {
            span {
                margin-top: 15px;
                padding: 0 3px;
                line-height: 15px;
            }
        }
    }

    &.expense-filter-box {
        width: 150px;
        height: 50px;
        border-radius: 10px;
        box-shadow: none;
        border: none;

        &:not(.selected) {
            background-color: #eee !important;
        }

        .expense-box {
            border-radius: 10px;
            height: 30px;

            &.label-box {
                i {
                    display: inline;
                    color: $light-black;
                    opacity: 0.75;
                }

                span {
                    opacity: 1;
                    top: -10px;
                    margin-left: 10px;
                }
            }
        }

        &.selected {
            .expense-box {
                &.label-box {
                    i {
                        opacity: 1;
                        color: $white !important;
                    }

                    span {
                        color: $white !important;
                    }
                }
            }
        }
    }
}

.label-box {
    text-align: center;
    margin: 15px 30px 0 25px;

    i {
        display: block;
        font-size: 33px;
        color: $primary;
    }

    span {
        font-size: 14px;
        opacity: 0.5;
        position: relative;
        top: -3px;
    }

    p {
        font-size: 16px;
    }
}

.modal-header {
    border: none;

    .modal-title {
        width: 100%;
        text-align: center;
    }

    .btn-close {
        position: absolute;
        right: 12px;
        top: 12px;
        background: none !important;
        margin: 0;
        opacity: 1;

        img {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }

        i {
            font-size: 20px;
            cursor: pointer;
            color: $dark-orange;
        }
    }
}

.ng-select-icon-add {
    font-size: 20px;
    position: relative;
    top: 5px;
    margin: 0 0 0 5px;
    color: $primary;
}

.fe-notification-icon {
    text-align: center;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border: 1px solid #ffa726;
    border-radius: 50%;
    position: relative;

    i {
        font-size: 50px;
        color: #ffa726;
    }
}

.activity-content {
    position: relative;
    margin-bottom: 20px;

    .activity-header {
        height: 30px;
        padding: 4px 0px 4px 0px;
        margin-bottom: 10px;

        .activity-details {
            color: $orange-400;
            top: 0;
            position: absolute;
            transform: translateY(10%);
            right: 30px;

            .icon-arrow {
                font-size: 23px;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
            }
        }

        a {
            text-decoration: none;
            padding: 4px 8px 4px 8px;
        }
    }

    .reminder-wrapper {
        .reminder-box {
            padding: 16px;
            margin-bottom: 10px;
            border-radius: 8px;
            border: 1px solid $astral-100;
            position: relative;
            height: auto;
            transition: 0.25s all;

            &:last-child {
                margin-bottom: 10px;
            }

            &:hover {
                border: 1px solid $astral-300;
            }

            .inner {
                .reminder-description {
                    .remind-in-days {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        color: $astral-900;

                        img {
                            margin-right: 8px;
                        }
                    }
                }

                .img-holder {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 35px;
                    left: unset;

                    .reminder-icon {
                        float: left;
                        width: 38px;
                        height: 38px;
                        margin-right: 16px;
                        border: 2px solid $light-grey;
                        border-radius: 50%;
                        position: relative;

                        i {
                            font-size: 30px;
                            color: #0d392e;
                        }
                    }

                    .reminder-icon-right {
                        float: left;

                        .icon-arrow {
                            color: $black;

                            font-size: 20px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                h6 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: $aot-black-900;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 10px;
                    color: $aot-black-600;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    max-width: 215px;
                }

                .fe-context-action {
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}

.activity-log {
    margin-bottom: 10px;
    position: relative;
    height: 56px;
    transition: 0.25s all;
    cursor: pointer;
    border-left: 8px solid $astral-400;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 8px;
    transition: 0.5s all;

    &:hover {
        border-top: 1px solid $astral-100;
        border-right: 1px solid $astral-100;
        border-bottom: 1px solid $astral-100;
    }

    .activity-log-image {
        float: left;
        margin: 0 20px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        i {
            font-size: 30px;
        }
    }

    .activity-log-holder {
        left: 70px;

        p {
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            font-size: 14px;
            color: #1a1a1a;

            span {
                font-weight: 700;
            }
        }

        .time-ago {
            font-size: 10px;
            color: #4c5761;
            line-height: 12px;
            font-weight: 500;
            margin-top: 2px;
        }
    }
}

.no-data-container {
    p {
        max-width: 400px;
    }
}

// key-value-box start
.item-key-holder {
    border: 1px solid $border-color;
    border-radius: 10px;
    overflow: hidden;

    .item-value {
        font-size: 15px;
    }

    .item-key {
        padding: 13px 0 13px 0;
        text-align: center;
        border-right: 1px solid $border-color;

        &:first-of-type {
            background-color: #eee;
            border-bottom: 1px solid $border-color;
        }
    }

    .item-key-padding {
        padding: 0;

        &:first-of-type {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
        }

        &:last-of-type {
            padding-right: calc(var(--bs-gutter-x) * 0.5);

            .item-key {
                border-right: none;
            }
        }
    }
}

.icon-align-center {
    color: $primary;
}

.form-check-input {
    border-color: $primary;
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.form-check-input[type="checkbox"] {
    box-shadow: none !important;
}

.form-check-input[type="checkbox"] {
    border-radius: 3px;
}

.divider-line {
    margin: 25px auto;
    border: solid 1px $border-color;
    opacity: 0.3;
}

.key-value-holder {
    p:first-of-type {
        opacity: 0.75;
    }

    p:last-of-type {
        font-weight: 600;
    }
}

.input-group-aot {
    .input-group-prepend,
    .input-group-append {
        button {
            border-radius: 0;
            border: 1px solid #e5dfc7;
            height: 40px;
            line-height: 39px;
            padding: 0 15px;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }
    }

    .input-group-append {
        button {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    input.form-control {
        margin: 0;
        border-radius: 0 !important;
    }
}

.mobile-list {
    li {
        float: left;
        width: 100%;
        display: block;
        padding: 5px;
        border: 1px solid #eee;
        border-radius: 10px;
        position: relative;
        margin-bottom: 10px;

        .mobile-list-inner {
            height: 100%;
            float: left;
            width: 100%;

            .mobile-list-image-wrapper {
                float: left;
                max-width: 60px;
                min-width: 60px;
                height: auto;
                min-height: 75px;
                position: relative;

                i {
                    font-size: 40px;
                }

                img {
                    max-width: 45px;
                    width: auto;
                    margin: 0 auto;
                    display: block;
                }
            }

            .mobile-list-body {
                float: left;
                width: calc(100% - 60px);
                padding: 5px 0 0 0;

                .mobile-list-heading {
                    h2 {
                        font-size: 16px;
                        margin-bottom: 5px;
                    }
                }

                .mobile-list-sub-heading {
                    h6 {
                        font-size: 13px;
                        margin-bottom: 5px;
                        opacity: 0.5;
                    }
                }

                .mobile-list-features-holder {
                    span {
                        padding: 2px 7px;
                        font-size: 11px;
                        border: 1px solid #eee;
                        border-radius: 10px;
                        margin: 0 5px 0 0;
                        display: inline-block;

                        &.price {
                            color: $orange;
                            border-color: $orange;
                        }
                    }
                }
            }

            .fe-context-action {
                top: -5px;
                right: -3px;
            }
        }
    }
}

// redesign

.m-t-xs {
    margin-top: 8px;
}

.m-t-s {
    margin-top: 16px;
}

.m-t-m {
    margin-top: 24px;
}

.m-t-l {
    margin-top: 32px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 48px;
}

.m-t-xxxl {
    margin-top: 56px;
}

.m-t-xxxxl {
    margin-top: 64px;
}

.aot-yellow-gradient-bottom {
    background: $yellow-linear-gradient-to-bottom;
}

.aot-blue-bckg-to-right {
    background: $blue-linear-gradient-to-right;
}

.aot-blue-bckg-to-left {
    background: $blue-linear-gradient-to-left;
}

.onboarding-intro-bckg,
.onboarding-intro-bckg-2,
.onboarding-intro-bckg-3 {
    min-width: 100%;
    min-height: 100vh;
    position: relative;
    background-position: bottom;
    background-size: cover;
    background-image: url("/assets/img/rectangle_2.png");
    color: $white;

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffa726;
        mix-blend-mode: multiply;
        content: "";
    }

    .onboarding-intro-text {
        z-index: 10;
        position: relative;
    }
}

.onboarding-intro-bckg-2 {
    background-image: url("/assets/img/rectangle_01.png");
    background-position: center;
}

.onboarding-intro-bckg-3 {
    background-image: url("/assets/img/rectangle_3.png");
    background-position: center;
}

.onboarding-intro {
    max-height: 100vh;
    overflow: auto;
    min-height: 100vh;

    nav {
        max-width: 700px;
        margin: 0 auto;
        width: 90%;
    }
}

.icon-close:before {
    color: $aot-black-900;
    font-size: 26px;
}

.btn-link-2 {
    background: none;
    border: none;
    color: $orange-400;
    text-decoration: none;

    &:hover {
        background: none;
        border: none;
        color: $orange-400;
    }
}

.backdrop-blur-modal {
    backdrop-filter: blur(5px);
}

.backdrop-success-modal {
    .modal-dialog {
        .modal-content {
            background-color: transparent;
            border: none;

            .modal-success {
                max-width: 856px;
                margin: auto;
                background: $yellow-linear-gradient-to-bottom;
                box-shadow:
                    0px 4px 4px rgba(0, 0, 0, 0.25),
                    0px 4px 4px rgba(0, 0, 0, 0.08);
                border-radius: 16px;
            }
        }
    }
}

.backdrop-driver-success-modal {
    .modal-dialog {
        .modal-content {
            background-color: transparent;
            border: none;

            .modal-success {
                max-width: 856px;
                margin: auto;
                background: $blue-linear-gradient-to-right;
                box-shadow: 0px 48px 56px 0px #32324740;
                box-shadow: 0px 28px 28px 0px #32324726;
                border-radius: 16px;
            }
        }
    }
}

.fs-success-modal {
    .modal-dialog {
        .modal-content {
            background: $yellow-linear-gradient-to-bottom;
        }
    }
}

.dynamic-select-holder {
    width: 516px;
    height: 720px;
    backdrop-filter: blur(6px);

    .dynamic-select-holder-content {
        height: 720px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow:
            0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 4px rgba(0, 0, 0, 0.08);
        border-radius: 24px;

        .inner {
            margin: 0 80px;
        }
    }
}

ngb-datepicker {
    &.dropdown-menu {
        border: 1px solid $aot-black-200;
        box-shadow:
            0px 4px 4px rgba(0, 0, 0, 0.08),
            0px 4px 8px rgba(18, 18, 18, 0.06);
        border-radius: 16px;
        background-color: $white !important;
        max-width: 402px;
        // width: 100%;
    }

    .ngb-dp-content {
        .ngb-dp-month {
            flex: 0 0 100%;
        }
    }

    .ngb-dp {
        &-header {
            border-radius: 16px !important;
            background-color: $white !important;

            ngb-datepicker-navigation {
                height: 72px;

                .ngb-dp-navigation-chevron {
                    color: #f98007;
                }
            }

            .ngb-dp-month-name {
                background-color: transparent;
            }
        }

        &-month {
            .ngb-dp-weekdays {
                border-bottom: 1px solid rgba(102, 102, 102, 0.16);
                border-top: 1px solid rgba(102, 102, 102, 0.16);
                border-radius: 0;
                height: 48px;
                background-color: $white;

                .ngb-dp-day,
                .ngb-dp-weekday,
                .ngb-dp-week-number {
                    padding-top: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: $aot-black-900;

                    [ngbDatepickerDayView] {
                        width: 32px !important;
                        height: 32px !important;
                    }
                }
            }

            .ngb-dp-week {
                justify-content: center;

                .ngb-dp-weekday {
                    margin-top: 0px !important;
                }

                .ngb-dp-day,
                .ngb-dp-weekday,
                .ngb-dp-week-number {
                    margin: 5px 12px;

                    .bg-primary {
                        background-color: #00c48c !important;
                    }
                }
            }
        }
    }
}

span.badge {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: inline-block;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 2px 8px 2px 8px;
    background: #337487;
    border-radius: 8px;

    &.warning {
        background: $orange-600;
    }
    &.danger {
        background: $red-600;
    }
    &.success {
        background: $green-600;
    }
    &.online-indicator {
        width: 13px;
        height: 13px;
        display: inline-block;
        padding: 0;
        border-radius: 50%;
        margin-top: 5px;
        float: left;
    }
}

.status-box {
    margin: auto;
    padding: 16px;
    gap: 16px;
    position: relative;
    width: auto;
    height: auto;
    background: #ffffff;
    border-radius: 16px;
    float: left;
    width: 100%;

    .status-box-content {
        position: relative;
        border-bottom: 1px solid #e6e6e6;
        padding: 0px 0px 16px 0px;

        .grouped-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 11px;
        }

        h5 {
            max-width: 215px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 8px 0px 8px 0px;
            line-height: 24px;
            font-weight: 700;
            font-size: 16px;
            color: #1a1a1a;
        }

        .header-option {
            max-width: 500px;
        }
    }

    .status-box-option {
        width: 100%;
        float: left;
        border-bottom: 1px solid #e6e6e6;

        .status-box-details {
            padding: 0px 16px 0px 16px;
            width: 100%;

            .status-box-heading {
                text-transform: uppercase;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #000000;
            }

            .status-box-title {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: $aot-black-500;
                margin-bottom: 4px;
            }

            .status-box-intro {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                align-items: center;
                display: flex;
                color: #000000;
            }

            .status-progres-label {
                width: 50%;
                float: left;
            }

            &.multiple-items {
                float: 100%; // 100% width for multiple items too
                float: left;
            }
        }

        &.with-box-layout {
            border-bottom: none;
            .status-box-details {
                border: 1px solid #eee;
                padding: 20px;
                border-radius: 20px;
                background-color: #eeeeee2e;
            }
        }
    }

    .status-label {
        border-right: 1px dashed #e6e6e6;

        &:last-child {
            border-right: none;
        }
    }

    .small-divider-line {
        margin: 8px auto;
        border: 1px dashed #e6e6e6;
        opacity: 0.3;
    }

    .regular-divider-line {
        margin: 16px auto;
        border: 1px dashed #e6e6e6;
        opacity: 0.3;
        float: left;
        width: 100%;
    }
}

.status-progres {
    display: flex;
    align-items: center;
    justify-content: start;

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin: 0px;
    }

    .status-box-actions {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $aot-black-700;

        img {
            margin-right: 8px;
        }
    }

    .status-progres-label {
        width: 50%;
        float: left;
    }
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.fe-hidden {
    display: none;
}

.option-box-selected {
    cursor: pointer;
    background: $yellow-linear-gradient-to-bottom;
    box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.08),
        0px 4px 8px rgba(18, 18, 18, 0.06);
    color: #ffd888;
    border: 1px solid $orange-500 !important;
}

.option-box-not-selected {
    background-color: #fff8eb;
    height: 88px;
    width: 50%;
}

.hide-arrow::after {
    display: none !important;
}

.text-button {
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    border: none;
    background-color: $aot-black-500;
    cursor: pointer;

    span {
        float: left;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: $white;
    }

    img {
        float: left;
        margin: 0 auto;
        margin-left: 7px;
    }

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
        color: inherit !important;
    }

    &:hover {
        background-color: $aot-black-500;
        border-color: $aot-black-500;
    }
}

.vehicle-list-details {
    position: relative;
    width: 100%;
    float: left;

    .add-vehicle-list-details-button {
        margin-top: 7px;
        padding: 8px;
        border-bottom: 1px solid $aot-black-100;
        width: 100%;

        .btn-action {
            display: flex;
            align-items: center;
            justify-content: center;
            float: left;
            background: none;
            border: none;
            color: $orange-500;
            text-decoration: none;
            padding: 4px 8px;

            img {
                float: left;
                margin-right: 10px;
            }

            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: $orange-500;
            }
        }
    }

    .vehicle-list-details-ul {
        position: relative;
        float: left;
        width: 100%;

        .vehicle-list-details-li {
            padding: 8px 16px;
            float: left;
            width: 100%;
            position: relative;

            a {
                text-decoration: none;
                width: 100%;
                float: left;
                border-bottom: 1px solid $aot-grey;
                padding: 0px 0px 8px 0px;

                &:last-child {
                    border-bottom: none !important;
                }
            }

            .vehicle-list-details-title {
                h5 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $aot-black-900;
                    margin: 0px;
                }
            }

            .vehicle-list-details-odometer {
                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: $aot-black-900;
                    margin: 0;
                }
            }

            .vehicle-list-details-info {
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: $aot-black-900;
                    margin: 0px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .vehicle-service-title {
                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            .remind-in-days {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: $astral-900;

                img {
                    margin-right: 8px;
                }

                p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $aot-black-700;
                }
            }

            .category-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 120px;
                width: 40px;
                height: 40px;
                border: 2px solid $light-grey;
                border-radius: 50%;

                i {
                    font-size: 30px;
                    color: $aot-forest-green;
                }
            }
        }
    }

    .greyed-out {
        border-radius: 8px;
        background-color: $aot-black-100;
    }
}

.grouped-icon-list {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;

    .date-info {
        float: left;

        span {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $aot-black-700;
            float: left;
            padding: 1px 10px;
        }
    }

    .update-icon,
    .delete-icon,
    .forward-arrow {
        float: left;

        img {
            float: left;
            cursor: pointer;

            &.icon-disabled {
                opacity: 0.5;
            }
        }

        &.icon-disabled {
            opacity: 0.5;
        }
    }

    .delete-icon {
        img {
            margin-left: 10px;
        }
    }

    .forward-arrow {
        margin-left: 10px;
    }
}

.vehicle-list-details-no-data {
    position: relative;

    .vehicle-list-details-image {
        text-align: center;
    }

    .vehicle-list-details-headers {
        h4 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            text-align: center;
            color: $aot-black-900;
            max-width: 260px;
            margin: 0 auto;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: $aot-black-900;
            max-width: 340px;
        }
    }

    .vehicle-list-details-actions {
        img {
            float: left;
            margin-right: 10px;
        }

        p {
            float: left;
            margin: 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: $white;
        }
    }
}

.odometer-box {
    height: 108px;
    padding: 16px;
    position: relative;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    background-color: $white;

    .odometer-content {
        height: 70px;

        .odometer-image {
            float: left;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .odometer-content-holder {
            float: left;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 15px;

            h5 {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: $astral-900;
            }
        }

        .btn-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            left: unset;
            cursor: pointer;
            background: none;
            border: none;
            text-decoration: none;

            i,
            p {
                color: $orange-500;
            }

            i {
                font-size: 25px;
            }
        }
    }
}

.aot-tooltip {
    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: $white;
    }

    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: $white;
    }

    &.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: $white;
    }

    &.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: $white;
    }

    .tooltip-inner {
        color: $black;
        box-shadow: 0px 4px 4px 0px #00000014;
        box-shadow: 0px 4px 4px 0px #00000040;
        background-color: $white;
        opacity: 1;
    }
}

.ng-select .ng-clear-wrapper {
    display: none;
}

.vehicle-list {
    position: relative;
    width: 100%;
    float: left;
    border: 1px solid $aot-grey;
    border-radius: 8px;

    a {
        text-decoration: none;
        color: $aot-black-900;
        width: 100%;
        float: left;
    }

    .vehicle-item {
        float: left;
        height: 48px;
        width: 100%;
        padding: 8px 16px;
        position: relative;

        .tooltip-holder {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &:hover {
            background-color: $aot-lemon-chiffon;
            border-radius: 8px 0px 0px 8px;
        }

        .summary-item {
            img {
                margin: 0px 10px;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: $aot-black-900;
                padding: 0px;
                margin: 0px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .summary-item-right {
            right: 35px;

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: $aot-black-500;
                position: relative;
                top: -1px;
            }
        }

        &:after {
            content: "";
            background-image: url("/assets/img/icons/CaretRight.svg");
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
        }

        &.vehicle-item-lock {
            a {
                pointer-events: none !important;
            }

            span {
                color: $aot-black-500 !important;
            }

            &:hover {
                background-color: $aot-black-50 !important;
            }

            &:after {
                background-image: url("/assets/img/icons/lock-simple.svg");
            }
        }

        a:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: calc(100% - 60px);
            padding: 8px 16px;
            border-bottom: 1px solid $aot-grey;
        }

        &.no-border a::after {
            border-bottom: none !important;
        }

        &:last-of-type {
            &:after {
                border-bottom: none !important;
            }
        }

        &.remove-vehicle-item {
            &:after {
                display: none;
            }
        }

        &.active {
            background-color: $aot-lemon-chiffon;
            border-radius: 8px 0px 0px 8px;
        }
    }

    .delete-vehicle {
        background: none;
        border: none;
        color: $red-600;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 10px;
    }
}

// reports - expenses-overiview
.expenses-box {
    border: 1px solid $aot-black-50;
    background-color: $aot-black-50;
    border-radius: 8px;
    position: relative;
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 16px;

    li {
        padding: 12px 16px;

        a {
            text-decoration: none;
            width: 100%;
            float: left;

            .expenses-heading {
                float: left;

                img {
                    margin: 0px auto;
                    margin-right: 10px;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $aot-black-900;
                }
            }

            .expenses-cost {
                float: right;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $aot-black-500;
                }

                img {
                    color: $aot-black-900;
                    margin: 0px auto;
                    margin-left: 10px;
                }
            }
        }
    }

    .divider {
        background-color: $aot-grey;
        height: 1px;
        padding: 0 !important;
        width: calc(100% - 50px);
        float: right;
    }
}

.recent-expense-content {
    margin: 8px;

    .sub-heading {
        p {
            text-align: left;
            margin: 0px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
        }
    }

    .expenses-month {
        border: none;
        border-radius: 0px;

        .expenses-month-box {
            padding: 8px 16px 8px 8px;
            border-bottom: 1px solid #e6e6e6;

            .expenses-heading {
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12px;
                }
            }

            .expenses-cost-recent {
                padding: 6px 0px;
            }
        }
    }
}

.with-icon {
    &:after {
        content: "";
        background-repeat: no-repeat;
        width: 21px;
        height: 21px;
        margin: 0 20px;
        padding: 0;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        z-index: 100;
    }

    &.down {
        &:after {
            background-image: url("/assets/img/icons/caret-down.svg");
        }
    }

    &:focus::after {
        opacity: 1;
    }
}

.empty-state-section {
    border: 1px solid $aot-black-100;
    border-radius: 8px;
    padding: 16px;
    float: left;
    position: relative;
    width: 100%;

    .empty-state-content {
        float: left;
        max-width: calc(100% - 70px);

        p {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $aot-black-900;
            margin: 0px;
        }

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $aot-black-900;
        }

        a {
            text-decoration: none;
            padding: 4px 8px 4px 8px;
            font-size: 12px;
            position: relative;

            .icon-arrow {
                font-size: 23px;
                margin-left: 8px;
                position: absolute;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
            }
        }

        .activity-details {
            color: $primary;
        }
    }

    .empty-state-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        left: unset;
    }
}

// Responsive
@media screen and (max-width: 991px) {
    .item-key-holder {
        .item-key {
            float: left;
            width: 50%;
            background-color: transparent !important;
            border-bottom: 1px solid $border-color;
            padding: 13px 0 13px 15px;

            .item-value.fe-opacity-light {
                font-weight: 600;
            }
        }

        .item-key-padding {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            padding-right: calc(var(--bs-gutter-x) * 0.5);

            .item-key {
                &:first-of-type {
                    text-align: left;
                }

                &:last-of-type {
                    border-right: none;
                    padding-left: 0;
                }
            }

            &:last-of-type {
                .item-key {
                    border-bottom: none;

                    &:first-of-type {
                        border-right: 1px solid $border-color;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .item-key-holder {
        .item-key {
            .item-value {
                font-size: 14px;
            }
        }
    }

    .aot-box {
        padding: 15px 20px;

        h5 {
            max-width: 85%;
        }
    }

    .mobile-overflow {
        position: relative;
        padding: 0;
        margin-left: -40px;
        width: calc(100% + 40px);
        max-width: unset;
    }

    .btn {
        &.fe-btn {
            min-width: 170px;
        }
    }

    footer {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .d-none-on-mobile {
        display: none !important;
    }
}
