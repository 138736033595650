.button-regular {
    background-color: $orange-500;
    border-color: $orange-500 ;
    border-radius: 8px;
    justify-content: space-between;

    &:focus,
    &:active {
        background-color: $orange-400;
    }

    &:hover {
        background-color: $orange-300;
        border-color: $orange-300;

    }

    &:disabled {
        background-color: $aot-black-100;
    }

    &.button-regular-outline {
        background-color: $orange-500;
    }
}

.btn-remove {
    background-color: #CC001F;
    border-color: #CC001F;
    border-radius: 8px;
    color: $white !important;
    justify-content: space-between;

    &:focus,
    &:active {
        background-color: #CC001F;
    }

    &:hover {
        background-color: #CC001F !important;
        border-color: #CC001F !important;
        color: $white !important;

    }

    &:disabled {
        background-color: $aot-black-100;
    }
}

.button-regular-rounded,
.button-x-small-rounded,
.button-small-rounded {
    border-radius: 30px !important;

    &.button-xs-small {
        padding: 4px 8px;
    }

    &.button-x-small {
        padding: 8px 16px;
    }

    &.button-small {
        padding: 8px 24px;
    }
}

.button-regular,
.with-backward-icon::before {
    font-family: 'Material Icons';
    content: "\e5cc";
    background-color: $aot-black;
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 6px 0 0;
}

.button-regular,
.with-forward-icon::after {
    font-family: 'Material Icons';
    content: "\e5cc";
    background-color: $aot-black;
    display: block;
    width: 20px;
    height: 20px;
    float: right;
    margin: 0 6px 0 0;
}