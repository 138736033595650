.form-control-regular {
    height: 48px;
    padding: 8px 16px;
    line-height: 40px;
    margin: 8px auto 16px;
    border-radius: 10px;
    border-color: $aot-black-200 ;

    &::placeholder {
        border-color: $aot-black-200;
        background: #FFFFFF ;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
    }

    &:focus {
        border-color: $aot-black-200 ;
        background: #FFFFFF ;
        border: 1px solid #CCCCCC ;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(18, 18, 18, 0.06) ;
        border-radius: 8px ;
    }

    &:invalid {
        border-color: $red-500;
        background: #FFFFFF;
        border: 1px solid $red-500;
        box-shadow: 0px 4px 8px rgba(255, 51, 82, 0.08);
        border-radius: 8px;
    }

    &:hover {
        border-color: $aot-black-200;
        background: #FFFFFF;
        border: 1px solid $aot-black-200;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(18, 18, 18, 0.06);
        border-radius: 8px;
    }

    &::-ms-value {
        border-color: $aot-black-200;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
    }

    &:disabled {
        border-color: $aot-black-100;
        background: #E6E6E6;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
    }

    .form-control-regular,
    .success {
        border-color: $green-500;
        background: #FFFFFF;
        border: 1px solid #00CC92;
        color: #00CC92;
        box-shadow: 0px 4px 8px rgba(0, 204, 146, 0.08);
        border-radius: 8px;
    }


}